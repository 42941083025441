var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    [
      _c(
        "b-row",
        { staticClass: "mb-2" },
        [
          _c(
            "b-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "b-form-group",
                [
                  _c("flat-pickr", {
                    staticClass: "form-control",
                    attrs: {
                      config: {
                        enableTime: false,
                        noCalendar: false,
                        dateFormat: "Y-m-d"
                      },
                      placeholder: "Select Data"
                    },
                    model: {
                      value: _vm.filters["start"],
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "start", $$v)
                      },
                      expression: "filters['start']"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "9" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "float-right",
                  attrs: { size: "sm", variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.requestLeave()
                    }
                  }
                },
                [_vm._v("Request Leave")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-overlay",
                { attrs: { show: _vm.tableLoading, rounded: "sm" } },
                [
                  _c("b-table", {
                    ref: "table",
                    staticClass: "mobile_table_css",
                    attrs: {
                      "current-page": _vm.currentPage,
                      "per-page": _vm.pagination.perPage,
                      fields: _vm.fields,
                      filter: _vm.filter,
                      "filter-included-fields": _vm.filterOn,
                      items: _vm.getMyLeaves,
                      "sort-by": _vm.sortBy,
                      "sort-desc": _vm.sortDesc,
                      "sort-direction": _vm.sortDirection,
                      hover: "",
                      responsive: ""
                    },
                    on: {
                      "update:sortBy": function($event) {
                        _vm.sortBy = $event
                      },
                      "update:sort-by": function($event) {
                        _vm.sortBy = $event
                      },
                      "update:sortDesc": function($event) {
                        _vm.sortDesc = $event
                      },
                      "update:sort-desc": function($event) {
                        _vm.sortDesc = $event
                      },
                      "row-clicked": _vm.openEditLeaveSideBar
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(duration)",
                        fn: function(data) {
                          return [
                            data.item.is_day
                              ? _c("div", [
                                  data.item.duration > 1
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(data.item.duration) + " days"
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(data.item.duration) + " day"
                                        )
                                      ])
                                ])
                              : _c("div", [
                                  data.item.duration > 1
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(data.item.duration) + " hours"
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(data.item.duration) + " hour"
                                        )
                                      ])
                                ])
                          ]
                        }
                      },
                      {
                        key: "cell(reason)",
                        fn: function(data) {
                          return [
                            _c("div", { staticStyle: { width: "150px" } }, [
                              _c("span", [_vm._v(_vm._s(data.item.reason))])
                            ])
                          ]
                        }
                      },
                      {
                        key: "cell(status)",
                        fn: function(data) {
                          return [
                            data.item.status === "Requested"
                              ? _c(
                                  "b-badge",
                                  {
                                    staticClass: "text-warning",
                                    staticStyle: { padding: "8px" },
                                    attrs: { variant: "light-warning" }
                                  },
                                  [
                                    _c("span", {}, [
                                      _vm._v(
                                        " " + _vm._s(data.item.status) + " "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            data.item.status === "Approved"
                              ? _c(
                                  "b-badge",
                                  {
                                    staticClass: "text-success",
                                    staticStyle: { padding: "8px" },
                                    attrs: { variant: "light-success" }
                                  },
                                  [
                                    _c("span", {}, [
                                      _vm._v(
                                        " " + _vm._s(data.item.status) + " "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            data.item.status === "Rejected"
                              ? _c(
                                  "b-badge",
                                  {
                                    staticClass: "text-danger",
                                    staticStyle: { padding: "8px" },
                                    attrs: { variant: "light-danger" }
                                  },
                                  [
                                    _c("span", {}, [
                                      _vm._v(
                                        " " + _vm._s(data.item.status) + " "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            data.item.status === "Cancelled"
                              ? _c(
                                  "b-badge",
                                  {
                                    staticClass: "text-info",
                                    staticStyle: { padding: "8px" },
                                    attrs: { variant: "light-info" }
                                  },
                                  [
                                    _c("span", {}, [
                                      _vm._v(
                                        " " + _vm._s(data.item.status) + " "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "cell(action)",
                        fn: function(data) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "ml-n3",
                                staticStyle: { width: "10px" }
                              },
                              [
                                data.item.status === "Requested"
                                  ? _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover.top",
                                            value: "Cancel",
                                            expression: "'Cancel'",
                                            modifiers: {
                                              hover: true,
                                              top: true
                                            }
                                          }
                                        ],
                                        staticClass:
                                          "btn-icon bg-white text-danger ml-1",
                                        attrs: {
                                          size: "23",
                                          variant: "outline-white"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteRequest(
                                              data.item.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "XSquareIcon",
                                            size: "18",
                                            variant: "outline-danger"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.noDataTable === 0
            ? _c(
                "b-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [_c("span", [_vm._v("No data for preview")])]
              )
            : _vm._e(),
          _c(
            "b-col",
            {
              staticClass:
                "d-flex align-items-center justify-content-center justify-content-sm-start",
              attrs: { cols: "12", sm: "6" }
            },
            [
              _c("span", { staticClass: "text-muted" }, [
                _vm._v(
                  "Showing " +
                    _vm._s(_vm.pagination.from) +
                    " to " +
                    _vm._s(_vm.pagination.to) +
                    " of " +
                    _vm._s(_vm.pagination.totalRows) +
                    " entries"
                )
              ])
            ]
          ),
          _c(
            "b-col",
            {
              staticClass:
                "d-flex align-items-center justify-content-center justify-content-sm-end",
              attrs: { cols: "12", sm: "6" }
            },
            [
              _c("b-pagination", {
                staticClass: "mb-0 mt-1 mt-sm-0 ",
                attrs: {
                  "per-page": _vm.pagination.perPage,
                  "total-rows": _vm.pagination.totalRows,
                  "first-number": "",
                  "last-number": "",
                  "next-class": "next-item",
                  "prev-class": "prev-item"
                },
                scopedSlots: _vm._u([
                  {
                    key: "prev-text",
                    fn: function() {
                      return [
                        _c("feather-icon", {
                          attrs: { icon: "ChevronLeftIcon", size: "18" }
                        })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "next-text",
                    fn: function() {
                      return [
                        _c("feather-icon", {
                          attrs: { icon: "ChevronRightIcon", size: "18" }
                        })
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("edit-leave", {
        ref: "EditLeaveSideBar",
        attrs: { "is-edit-leave-sidebar-active": _vm.isEditLeaveSidebarActive },
        on: {
          dataRefresh: function($event) {
            return _vm.dataRefreshLeave()
          },
          editSideBarClose: _vm.editSideBarClose,
          requestEditDataRefresh: _vm.requestEditDataRefresh
        }
      }),
      _c("request-leave", {
        ref: "RequestLeaveSideBar",
        attrs: {
          "is-request-leave-sidebar-active": _vm.isRequestLeaveSidebarActive
        },
        on: {
          requestLeave: function($event) {
            return _vm.requestLeave()
          },
          dataRefresh: function($event) {
            return _vm.dataRefreshLeave()
          },
          requestDataRefresh: _vm.requestDataRefresh
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }