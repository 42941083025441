<template>
  <b-sidebar
      id="request-leave-side-bar"
      :visible="isRequestLeaveSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-request-leave-sidebar-active', val)"
      @hidden="resetForm"
  >
    <template #default="{ hide }">
      <b-overlay
          :show="tableLoading"
          rounded="sm"
      >

        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4 class="mb-0">
            Request Leave 
          </h4>

          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
          />

        </div>
        <div class="p-2">
          <validation-observer ref="requestLeaveForm">
            <b-form @submit.prevent>
            <b-row>
              <b-col md="12">
                <h5 class="mb-2 cursor-pointer">
                  Leave Type</h5>
              </b-col>

              <b-col  md="6">
                <b-form-radio-group>
                  <b-form-radio
                      v-model="selectedLeaveType"
                      class="float-left"
                      value="timeOff"
                  >
                    Time Off
                  </b-form-radio>
                  <b-form-radio
                      v-model="selectedLeaveType"
                      class="float-right"
                      value="dayOff"
                  >
                    Day Off
                  </b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row class="mt-2" v-if="selectedLeaveType === 'timeOff'">
              <b-col cols="12" md="6">
                <label>Date</label>
                <validation-provider
                      #default="{ errors }"
                      name="Date"
                      rules="required"
                  >
                <b-form-group>
                  <flat-pickr
                      v-model="selectedStartDate"
                      :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d', minDate: new Date()}"
                      class="form-control"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-col>
              <b-col cols="12" md="3">
                <label>Start Time</label>
                <b-form-group>
                  <validation-provider
                      #default="{ errors }"
                      name="Start Time"
                      rules="required"
                  >
                    <flat-pickr
                        v-model="selectedStartTime"
                        :config="{ enableTime: true, noCalendar: true,  dateFormat: 'H:i',  }"
                        class="form-control"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3">
                <label>End Time</label>
                <validation-provider
                    #default="{ errors }"
                    name="End Time"
                    rules="required"
                >
                  <b-form-group>
                    <flat-pickr
                        v-model="selectedEndTime"
                        :config="{ enableTime: true, noCalendar: true,  dateFormat: 'H:i',}"
                        class="form-control"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mt-2" v-if="selectedLeaveType === 'dayOff'">
              <b-col cols="12" md="6">
                <label>Start Date</label>
                <validation-provider
                    #default="{ errors }"
                    name="Start Date"
                    rules="required"
                >
                <b-form-group>
                  <flat-pickr
                      v-model="selectedDayOffStartDate"
                      :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d',  minDate: new Date()}"
                      class="form-control"
                  />

                </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small></validation-provider>
              </b-col>
              <b-col cols="12" md="6">
                <label>End Date</label>
              <validation-provider
                    #default="{ errors }"
                    name="End Date"
                    rules="required"
                >
                <b-form-group>
                  <flat-pickr
                      v-model="selectedDayOffEndDate"
                      :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d',  minDate: new Date()}"
                      class="form-control"
                  />

                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small></validation-provider>
              </b-col>


            </b-row>
            <b-row>
              <b-col md = 12>
                <label>Reason</label>
                <validation-provider
                    #default="{ errors }"
                    name="Reason"
                    rules="required"
                >
                <b-form-textarea
                    v-model="form.selectedReason"
                    id="textarea-default"
                    placeholder="Enter reason"
                    rows="3"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

              <div class="d-flex mt-3">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    @click="requestLeave()"
                >
                  Submit request
                </b-button>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="text-primary ml-1"
                    type="button"
                    variant="primary"
                    @click="hide"
                >
                  Cancel
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {

  BRow,
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BSidebar,
  BInputGroupPrepend,
  BCol,
  BTable,
  BCard,
  BAvatar,
  BLink,
  BBadge,
  BCardBody,
  BCardHeader,
  BImg,
  BMedia,
  BFormSelect,
  BPagination,BFormRadioGroup,BFormRadio,
  BInputGroupAppend, BDropdown, BDropdownItem, BListGroup, BListGroupItem, VBTooltip, BOverlay, BFormTextarea


} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import settings from '@/apis/modules/settings'
import Filter from '@/mixins/FilterMixin'
import flatPickr from 'vue-flatpickr-component'
import MomentMixin from '@/mixins/MomentMixin'
import leave from '@/apis/modules/leave'
import moment from 'moment'
import {email, required} from '@validations'

export default {
  name: 'requestLeave',

  components: {
    flatPickr, BAlert, BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BInputGroup,
    BButton, BFormDatepicker, BFormCheckbox, vSelect, VuePerfectScrollbar, draggable, BRow, BCol, BInputGroupPrepend,
    // Form Validation
    ValidationProvider, ValidationObserver, BCard,BFormRadioGroup,BFormRadio,
    BAvatar, BLink, BBadge, BCardBody, BCardHeader, BImg, BMedia, BTable, BFormSelect, BPagination, BInputGroupAppend,
    BDropdown,
    BDropdownItem, BListGroup, BListGroupItem, BOverlay, VBTooltip, BFormTextarea
  },
  mixins: [Filter, MomentMixin],

  directives: {
    Ripple
  },
  model: {
    prop: 'isRequestLeaveSidebarActive',
    event: 'update:is-request-leave-sidebar-active'
  },
  props: {
    isRequestLeaveSidebarActive: {
      type: Boolean,
      required: true
    },

  },
  data () {
    return {
      required,
      form:{
        selectedReason: '',
      },
      test:'',
      selectedStartDate:'',
      selectedDayOffStartDate:'',
      selectedDayOffEndDate:'',
      selectedStartTime:'',
      selectedEndTime:'',
      selectedLeaveType:'timeOff',
      tableLoading:false,
      filters: {},
      selected: [],
      selectedCheck: true,

      perfectScrollbarSettings: {
        maxScrollbarLength: 100
      },
      selectedOption: {
        title: 'All',
        value: 1
      },
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      fields: [
        {
          key: 'name',
          label: ' NAME'
        },
        {
          key: 'type',
          label: 'Type'
        }


      ],
      items: []
    }
  },
  methods: {
    hide(){
      this.$emit('requestLeave')
    },
    resetForm () {

    },
    resetData() {
      this.selectedLeaveType = '',
      this.selectedStartDate = '',
      this.selectedStartTime = '',
      this.selectedEndTime = '',
      this.selectedDayOffStartDate = '',
      this.selectedDayOffEndDate = '',
      this.form.selectedReason = ''
    },

    async requestLeave () {
      let response = [];
      if (await this.$refs.requestLeaveForm.validate()) {
        try {
          this.tableLoading = true

          if (this.selectedLeaveType === 'timeOff'){
            let payload = {
            start: moment(this.selectedStartDate + ` ` + this.selectedStartTime).utc().format('YYYY-MM-DD HH:mm:ss'),
            end: moment(this.selectedStartDate + ` ` + this.selectedEndTime).utc().format('YYYY-MM-DD HH:mm:ss'),
              reason:this.form.selectedReason,
              is_day:false,
            }
            response = await  leave.requestLeave(payload)
          }else {
            let payload = {
              start: moment(this.selectedDayOffStartDate).utc().format('YYYY-MM-DD HH:mm:ss'),
              end: moment(this.selectedDayOffEndDate ).utc().format('YYYY-MM-DD HH:mm:ss'),
              reason:this.form.selectedReason,
              is_day:true,

            }
            response = await  leave.requestLeave(payload)
          }
          if(response.data.data.error){
            this.showErrorMessage('There is an existing leave in this time slot ')
          }else {
            this.showSuccessMessage('Request Leave Successfully ')
            this.resetData()
            this.$emit('requestDataRefresh')
            this.$emit('requestLeave')
          }
          this.tableLoading = false
        } catch (error) {
          this.convertAndNotifyError(error)
          this.tableLoading = false

        }
      }
    },

  },
  watch:{
    isRequestLeaveSidebarActive(){
       this.$refs.requestLeaveForm.reset();
    }
  },
  mounted () {
  }

}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#request-leave-side-bar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }

  //.table thead th {
  //  border-bottom: 2px solid #ebe9f1;
  //  visibility: hidden !important;
  //}
}
</style>
<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }

}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>

