<template>


  <b-card>
    <b-row class="mb-2">
      <b-col  cols="3">
        <b-form-group>
          <flat-pickr
              v-model="filters['start']"
              :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d',}"
              class="form-control"
              placeholder="Select Data"
          />
        </b-form-group>
      </b-col>

      <b-col cols="9">
        <b-button @click="requestLeave()" class="float-right" size="sm"
                  variant="primary">Request Leave</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-overlay
            :show="tableLoading"
            rounded="sm"
        >
        <b-table
            :current-page="currentPage"
            :per-page="pagination.perPage"
            :fields="fields"
            :filter="filter"
            :filter-included-fields="filterOn"
            :items="getMyLeaves"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @row-clicked="openEditLeaveSideBar"
            hover
            ref="table"

            class="mobile_table_css"
            responsive
        >

          <template #cell(duration)="data">
           <div v-if="data.item.is_day">
             <span v-if="data.item.duration > 1">{{data.item.duration}} days</span>
             <span v-else >{{data.item.duration}} day</span>
           </div>
            <div v-else>
              <span v-if="data.item.duration > 1" >{{data.item.duration}} hours</span>
              <span v-else >{{data.item.duration}} hour</span>

            </div>


          </template>

          <template #cell(reason)="data">
            <div style="width:150px">
              <span>{{data.item.reason}}</span>
           </div>
          </template>


          <template #cell(status)="data">
            <b-badge v-if="data.item.status ==='Requested'" class="text-warning" style="padding:8px" variant="light-warning" >
              <span class=""> {{data.item.status}} </span>
            </b-badge>
            <b-badge v-if="data.item.status ==='Approved'" class="text-success" style="padding:8px" variant="light-success" >
              <span class=""> {{data.item.status}} </span>
            </b-badge>
            <b-badge v-if="data.item.status ==='Rejected'" class="text-danger" style="padding:8px" variant="light-danger" >
              <span class=""> {{data.item.status}} </span>
            </b-badge>
            <b-badge v-if="data.item.status ==='Cancelled'" class="text-info" style="padding:8px" variant="light-info" >
              <span class=""> {{data.item.status}} </span>
            </b-badge>


          </template>
          <template #cell(action)="data">
            <div style="width:10px" class="ml-n3">
            <b-button v-if="data.item.status ==='Requested'"
                      v-b-tooltip.hover.top="'Cancel'"
                size="23"
                variant="outline-white"
                class="btn-icon bg-white text-danger ml-1"
                @click="deleteRequest(data.item.id)"
            >
              <feather-icon icon="XSquareIcon" size="18" variant="outline-danger"/>
            </b-button>
            </div>
          </template>

        </b-table>
        </b-overlay>
      </b-col>
      <b-col cols="12" v-if="noDataTable === 0" class="text-center">
        <span >No data for preview</span>
      </b-col>
      <!-- Pagination -->

      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
        <span class="text-muted">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
      </b-col>

      <!-- Pagination -->

      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

        <b-pagination
            v-model="currentPage"
            :per-page="pagination.perPage"
            :total-rows="pagination.totalRows"
            class="mb-0 mt-1 mt-sm-0 "
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item">

          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18"/>
          </template>

          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18"/>
          </template>

        </b-pagination>

      </b-col>
    </b-row>
    <edit-leave
        ref="EditLeaveSideBar"
        :is-edit-leave-sidebar-active="isEditLeaveSidebarActive"
        @dataRefresh="dataRefreshLeave()"
        @editSideBarClose="editSideBarClose"
        @requestEditDataRefresh="requestEditDataRefresh"
    />
    <request-leave
        ref="RequestLeaveSideBar"
        :is-request-leave-sidebar-active="isRequestLeaveSidebarActive"
        @requestLeave="requestLeave()"
        @dataRefresh="dataRefreshLeave()"
        @requestDataRefresh="requestDataRefresh"
    />
  </b-card>



</template>

<script>

import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BOverlay,
  BPagination,
  BRow,
  BSidebar,
  BTab,
  BTable,
  BTabs,
  VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import leave from '@/apis/modules/leave'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import MomentMixin from '@/mixins/MomentMixin'
import vSelect from 'vue-select'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import EditLeave from '@/views/my-leaves/sideBar/editLeave'
import RequestLeave from '@/views/my-leaves/sideBar/requestLeave'
import DateDiff from 'date-diff'
import Filter from '@/mixins/FilterMixin'
import flatPickr from 'vue-flatpickr-component'
export default {
  components: {
    flatPickr,
    RequestLeave,
    EditLeave,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    vSelect,
    BInputGroupPrepend,
    BCard,
    BTabs,
    BTab,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormInvalidFeedback,
    BSidebar,
    BFormTextarea,
    VBTooltip,
    BOverlay


  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],

  data() {
    return {
      selectedLeave:{},
      isEditLeaveSidebarActive:false,
      isRequestLeaveSidebarActive: false,
      currentPage: 1,
      filters:{},
      filter: null,
      filterOn: [],
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'date',
          label:'Date',
        },
        {
          key: 'time',
          label: 'Time',
        },
        {
          key: 'duration',
          label: 'Duration',
        },


        {
          key: 'reason',
          label:'Reason',
        },
        {
          key: 'review',
          label:' APPROVED BY',
        },
        {
          key: 'status',
          label:' Status',
        },

        {
          key: 'action',
          label:' ',
        },


        // {
        //   key: 'action',
        //   label: '',
        // },

      ],
      items: [],
      noDataTable:'',
      tableLoading:false,
      jobRoleOption: [],
      systemRoleOption:[],
      user_name:'',
      user_email:'',
      user_id:'',
      modelShow: false,
      email: '',
      system_role_ids: [],
      job_role_id: '',
    }
  },
  watch: {
    currentPage () {

      this.getMyLeaves()
    }
  },

  methods:{
    async deleteRequest(id){
      try{
        this.$swal({
          title: 'Are you sure you want to cancel this Leave request?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, Cancel',
          cancelButtonText: 'No, go back',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then(async result => {

          if (result.value) {

            await leave.deleteLeave(id)
            this.$refs.table.refresh()

          }
        })
        // await leave.deleteLeave(id)
        // this.$refs.table.refresh()
        // this.$swal({
        //     icon: 'success',
        //     title: 'Canceled!',
        //     text: 'Your request has been canceled.',
        //
        //     customClass: {
        //       confirmButton: 'btn btn-success',
        //     },
        //   })
      }catch (error){
        this.convertAndNotifyError(error)
      }

    },
    async dataRefreshLeave(){
      await this.getMyLeaves()
      this.isRequestLeaveSidebarActive = false
      this.isEditLeaveSidebarActive= false

    },
    async requestDataRefresh(){
      this.$refs.table.refresh()

    },
    requestEditDataRefresh(){
      this.$refs.table.refresh()
    },
    async editSideBarClose(){
      this.isEditLeaveSidebarActive = !this.isEditLeaveSidebarActive
    },
    requestLeave() {
      this.isRequestLeaveSidebarActive = !this.isRequestLeaveSidebarActive
    },
    async openEditLeaveSideBar(data){
      if(data.status == "Requested"){
        this.$refs.EditLeaveSideBar.setData(data)
        this.selectedLeave = data
        this.isEditLeaveSidebarActive = !this.isEditLeaveSidebarActive
      }

    },

    async getMyLeaves(){
      try {
        this.tableLoading = true
        const Response = await leave.getLeaves( 10 , this.currentPage, this.filterQuery)
        this.noDataTable = Response.data.data.length

        let dataArray =  Response.data.data.map((x) => ({
          id: x.id,
          date: x.is_day?`${this.momentFormat(x.start,'ddd DD MM YYYY')}`+` - ` +`${ this.momentFormat(x.end,'ddd DD MM YYYY')}`:this.momentFormat(x.start,'ddd DD MM YYYY'),
          start: x.start,
          is_day: x.is_day,
          end: x.end,
          duration: x.is_day?`${new DateDiff( new Date(x.end), new Date(x.start)).days()}`: `${new DateDiff( new Date(x.end), new Date(x.start)).hours()}`,
          reason: x.reason === null ? 'N/A' : x.reason,
          review: x.administrator === null ? 'N/A' : `${x.administrator.first_name}  ${x.administrator.last_name}`,
          status: x.state.charAt(0).toUpperCase() + x.state.substring(1),
          time:x.is_day?'Full day': `${this.momentFormat(x.start,'HH:mm')}` + ' - ' + `${this.momentFormat(x.end,'HH:mm')}`
        }))

        this.tableLoading = false
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage= paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to

        return dataArray



      }catch (error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    filterQueryUpdate () {
      this.$refs.table.refresh()
    },
  },



  mounted() {

    // this.getUsers()
    this.getMyLeaves()

  },


}
</script>
<style>
div#dropdown-1 .btn-outline-danger {
  border: none !important;
}
</style>
